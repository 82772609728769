@import "../../../styles/variables.scss";

.search-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  background: $fourth-color;

  .search-input-container {
    position: relative;

    .search-label {
      padding: 0 $space-md;
      font-weight: $font-weight-bold;
    }

    .search-input-group {
      height: 50px;
      position: relative;
      padding: 0 $space-md;

      display: flex;
      align-items: center;
      gap: $space-md;

      background: $fourth-color;
      border-radius: $border-radius-lg;
      border: 1px solid $primary-color;

      overflow: scroll;
      z-index: 2;

      .search-icon {
        width: 20px;
      }

      .search-input {
        width: 100%;
        // margin: auto 0;

        color: $secondary-color;

        background: transparent;
        border: none;
        outline: none;
      }
    }

    .blank {
      height: 20px;
      transform: translateY(-20px);
      box-shadow: 0 5px 5px #ccc;
      z-index: 1;
    }

    .suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: $space-lg;

      background-color: #fff;
      border-radius: 0 0 $border-radius-lg $border-radius-lg;
      box-shadow: 0 5px 5px #ccc;
      padding: 20px;
      transform: translateY(-20px);

      z-index: 3;
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: $space-sm;
  }
}
