@import "../../../styles/variables.scss";

.quote-editable-card {
  width: 100%;
  min-height: 150px;
  padding: $space-md;
  justify-content: center;
  align-items: center;

  textarea {
    width: 80%;
    margin: 0;
    padding: 0;

    font-size: $font-size-h1;
    font-weight: $font-weight-extra-bold;
    border-bottom: solid $secondary-color;
    border-width: 0 0 1px 0;
    text-align: center;

    resize: none;
  }
}

.quote-text {
  width: 80%;
  margin: 0;

  font-size: $font-size-h1;
  font-weight: $font-weight-extra-bold;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
